.footer{
    background-color: #faa72a;
    min-height: 200px;
    color: black;
    padding: 2rem 1rem;
    font-size: 0.8rem;
}

@media screen and (min-width: 500px) {
    .footer{
        padding: 2rem 3rem;
        font-size: 1rem;
    }
}

.footerMain{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 2rem;
}

.col1{
    text-align: left;
}

.col2{
    text-align: right;
}

.col3{
    padding-top: 5.5rem;
    text-align: right;
}

@media screen and (max-width: 500px){
    .col3{
        padding-top: 8rem;
    }
}

.disclaimer{
    text-align: center;
}

.footerCompany{
    font-weight: bold;
    font-family: 'Merriweather';
    font-size: 2rem;
    margin-bottom: 10px;
}