.brandsContainer {
    background-color: white;
}

.brandsTitle {
    display: flex;
    color: black;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
    text-align: center;
    font-weight: bold;
    margin: 2rem;
}

.brands {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 3rem;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}

.brandImage {
    max-width: 200px;
    width: 100%;
    margin: auto;
    max-height: 200px;
}

@media screen and (max-width: 500px) {
    .brands {
        grid-template-columns: 1fr 1fr;
        padding: 2rem;
    }
    .brandImage {
        max-width: 150px;
    }
    .brandsTitle {
        font-size: 2rem;
        font-weight: bolder;
    }
}