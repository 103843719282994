.navbar {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: row;
    height: 100px;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 3rem;
    margin: 2rem 0;
    background-color: rgba(31, 31, 31, 0.8);
    z-index: 2;
}

.meetingBtn {
    border-radius: 50px;
    min-width: 125px;
    min-height: 40px;
    background-color: transparent;
    border: 1px solid #1f1f1f;
    color: black;
    background-color: orange;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.buttonInnerText {
    width: 100%;
    height: 100px;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 25%;
    transform: translate(-50%);
    white-space: nowrap;
    transition: 0.5s ease;
}

.buttonInnerText:hover{
    top: -50%;
}

.logo {
    width: 300px;
}

.meetingBtn {
    width: 175px;
    height: 50px;
}

@media screen and (max-width: 426px) {
    .navbar {
        height: 100px;
        padding: 0rem 1rem;
        margin: 0rem;
    }

    .logo {
        width: 40%;
        min-width: 150px;
    }

    .meetingBtn {
        width: 40%;
        height: 40px;
        font-size: 12px;
    }
}