*{
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1F1F1F;
  color: white;
  /* color: '#1F1F1F'; */
  /* background-color: rgb(211, 211, 211); */
  font-family: 'Poppins', sans-serif;
}

a{
  text-decoration: none;
}

.commonText{
  font-size: 1.25rem;
}

.titleText{
  font-size: 3rem;
  font-family: 'Merriweather';
  font-weight: lighter;
}

.CTA{
  border-radius: 50px;
  min-width: 100px;
  min-height: 40px;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  background-color: #1F1F1F;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bold{
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.inactiveNav{
  background-color: #cbcbcb;
  transition: 0.3s ease;
}

.activeNav{
  background-color: orange;
  transition: 0.3s ease;
}
/* 
.M, .C, .P, .A, .R{
  transition: 0.5s ease;
} */

.show0, .show1, .show2, .show3, .show4{
  animation: fadeInAndOut 7s infinite;
}

@keyframes fadeInAndOut{
  0%{
    opacity: 0
  }
  50%{
    opacity: 1;
  }
  90%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}