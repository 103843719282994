.bannerContainer{
    height: 90vh;
}

.banner{
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    position: relative;
    height: 60%;
    background-image: linear-gradient(to bottom, transparent, transparent, transparent, transparent, #1F1F1F), url(../../assets/buddha3.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.joinUsBtn {
    border-radius: 50px;
    min-width: 100px;
    min-height: 50px;
    background-color: transparent;
    border: 1px solid #1F1F1F;
    color: black;
    /* background-color: #1F1F1F; */
    background-color: orange;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.buttonInnerText {
    width: 100%;
    height: 100px;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 25%;
    transform: translate(-50%);
    white-space: nowrap;
    transition: 0.5s ease;
}

.buttonInnerText:hover{
    top: -50%;
}

.buddha{
    display: none;
}

.bannerContent{
    width: 90%;
    position: absolute;
    top: 120%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
}

.bannerTitle{
    font-size: 1.5rem;
}

.bannerText{
    position: relative;
    top: 1rem;
    font-size: 0.8rem;
}

.joinUsBtn{
    width: 125px;
    position: relative;
    top: 2rem;
}

@media screen and (min-width: 500px) and (max-width: 768px) {
    .bannerContainer{
        height: 50vh;
    }

    .banner{
        background-image: none;
        height: 80vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0rem 3rem;
    }

    .buddha{
        display: block;
        width: 50%;
        max-width: 600px;
        height: fit-content;
        max-height: 400px;
    }

    .bannerContent{
        width: 50%;
        position: relative;
        top: 0px;
        left: 30px;
        transform: none;
    }

    .bannerTitle{
        font-size: 2rem;
    }

    .bannerText{
        font-size: 1rem;
    }
    
    .joinUsBtn{
        width: 175px;
    }
}


@media screen and (min-width: 769px) {
    .bannerContainer{
        height: 80vh;
    }

    .banner{
        background-image: none;
        height: 80vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0rem 3rem;
    }

    .buddha{
        display: block;
        width: 50%;
        max-width: 600px;
        width: fit-content;
        height: fit-content;
        max-height: 600px;
        border-radius: 25px;
    }

    .bannerContent{
        width: 50%;
        position: relative;
        top: 50px;
        left: 30px;
        transform: none;
    }

    .bannerTitle{
        font-size: 3rem;
    }

    .bannerText{
        font-size: 1.5rem;
    }
    
    .joinUsBtn{
        width: 175px;
    }
}