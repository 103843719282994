.clientsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 200px;
    margin: 3rem 0rem;
    position: relative;
}

.miniContainer {
    width: 100%;
    overflow: hidden;
}

.mapping {
    box-shadow: 0px 0px 25px #1F1F1F inset;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    background-color: white;
    animation: scroll 30s linear infinite;
    padding: 1.5rem 0rem;
}

@keyframes scroll {
    0%{
        transform: translate(0%);
    }

    100%{
        transform: translate(-50%);
    }
}

.clientsContainer::-webkit-scrollbar {
    display: none;
}

.clientImg {
    width: 200px;
    margin:0rem 3rem;
}

@media screen and (max-width: 500px) {
    .clientImg{
        width: 150px;
    }
}