.contactContainer{
    padding: 2rem;
    width: 100%;
    max-width: 700px;
    margin: auto;
    text-align: center;
    min-height: 50vh;
}

.contactTitle{
    font-family: 'Merriweather';
    font-weight: lighter;
    font-size: 2rem;
}

.formContainer{
    width: 100%;
}

.inputText, .textarea{
    width: 100%;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 1rem;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 3px;
    color: white;
}

input:focus, textarea:focus{
    outline: none;
}

/* Button */
.meetingBtn {
    border-radius: 50px;
    min-width: 150px;
    min-height: 40px;
    background-color: transparent;
    border: 1px solid orange;
    font-family: 'Poppins', sans-serif;
    color: orange;
    background-color: #1F1F1F;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.buttonInnerText {
    width: 100%;
    height: 100px;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 25%;
    transform: translate(-50%);
    white-space: nowrap;
    transition: 0.5s ease;
}

.buttonInnerText:hover{
    top: -60%;
}

.radioInput{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 1rem;
}

.radioBtn{
    margin-right: 4rem;
}

@media screen and (min-width: 600px) {
    .contactTitle{
        font-size: 3rem;
        margin-bottom: 3rem;
    }
}
