.policySection{
    padding: 0rem 2rem;
    font-size: 0.8rem;
}

.policyTitle{
    font-family: 'Merriweather';
    text-decoration: underline;
}

.policyInfo{
    line-height: 20px;
}

@media screen and (min-width: 500px) {
    .policySection{
        padding: 3rem;
    }
}