.strengthContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 2rem 3rem 2rem;
    margin: 3rem 0rem;
    overflow: hidden;
}

.titleContainer {
    text-align: center;
    margin-bottom: 4rem;
}

.strengthTitle {
    font-family: 'Merriweather';
    font-size: 2rem;
}

.strengthSpan {
    font-size: 0.9rem;
    text-align: center;
    margin-bottom: 1rem;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.textContainer {
    margin: 1.5rem;
    font-size: 12px;
    text-align: center;
}

.circle {
    position: relative;
    width: 300px;
    height: 300px;
    border: 1px solid orange;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle2 {
    position: relative;
    bottom: 30px;
}

.circle3 {
    position: relative;
    bottom: 60px;
}

@media screen and (min-width: 650px) and (max-width: 1024px) {
    .strengthTitle {
        font-size: 3rem;
        font-weight: lighter;
    }

    .strengthSpan {
        font-size: 1.5rem;
    }

    .container {
        margin-top: 3rem;
        display: flex;
        flex-direction: row;
    }

    .textContainer {
        margin: 1.5rem;
        font-size: 10px;
        text-align: center;
    }

    .circle {
        width: 250px;
        height: 250px;
    }

    .circle1 {
        position: relative;
        left: 20px;
    }

    .circle2 {
        position: relative;
        bottom: 0;
    }

    .circle3 {
        position: relative;
        bottom: 0;
        right: 20px;
    }

    .circle:before,
    .circle:after {}
}

.circle:before,
.circle:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid orange;
    border-radius: 50%;
}

.circle1:before {
    animation: ripple 3s linear infinite;
}

.circle2:before{
    animation: ripple2 3s linear infinite;
}

.circle3:before{
    animation: ripple3 3s linear infinite;
}

.circle:after {
    /* animation: ripple 4s linear 1s infinite; */
}

@keyframes ripple {
    0% {
        transform: scale(1);
        opacity: 0.6
    }

    33%{
        transform: scale(1.3);
        opacity: 0;
    }
    
    100%{
        transform: scale(1.3);
        opacity: 0;
    }
}

@keyframes ripple2 {
    0%{
        transform: scale(1);
        opacity: 0.6
    }
    33%{
        transform: scale(1);
        opacity: 0.6;
    }
    66%{
        transform: scale(1.3);
        opacity: 0;
    }
    100%{
        transform: scale(1.3);
        opacity: 0;
    }
}

@keyframes ripple3 {
    0%{
        transform: scale(1);
        opacity: 0.6
    }
    33%{
        transform: scale(1);
        opacity: 0.6;
    }
    66%{
        transform: scale(1);
        opacity: 0.6;
    }
    100%{
        transform: scale(1.3);
        opacity: 0;
    }
}

@media screen and (min-width: 800px) and (max-width: 1349px){
    .container {
        margin-top: 3rem;
        display: flex;
        flex-direction: row;
    }

    .circle{
        width: 300px;
        height: 300px;
        position: relative;
        bottom: 0;
    }

    .circle1{
        left: 10px;
    }

    .circle3{
        right: 10px;
    }
}

@media screen and (min-width: 1350px) {
    .strengthContainer{
        margin-bottom: 5rem;
    }
    
    .strengthTitle {
        font-size: 3rem;
        font-weight: lighter;
    }

    .strengthSpan {
        font-size: 1.5rem;
    }

    .container {
        margin-top: 3rem;
        display: flex;
        flex-direction: row;
    }

    .textContainer {
        margin: 3rem;
        text-align: center;
        font-size: 1rem;
    }

    .circle {
        width: 450px;
        height: 450px;
    }

    .circle1 {
        position: relative;
        left: 20px;
    }

    .circle2 {
        position: relative;
        bottom: 0;
    }

    .circle3 {
        position: relative;
        bottom: 0;
        right: 20px;
    }
}

@media screen and (max-width: 282px) {
    .circle{
        width: 270px;
        height: 270px;
    }
}