.NumberSection{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.countContainer {
    display: flex;
    flex-direction: row;
}

.Number{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3rem 0rem;
}

.NumberValue{
    font-size: 3rem;
    font-weight: bold;
}

@media screen and (max-width: 600px) {
    .NumberSection{
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: top;
    }

    .Number{
        margin: 2rem 0rem;
    }

    .NumberValue{
        font-size: 2rem;
    }
}